var popupS = require('popups');
import popupSBasicStyles from "../../../node_modules/popups/css/popupS.min.css";

function cityModalInit(cityButton) {
    if (cityButton === undefined || !cityButton) {
        console.log('cityModalInit: no valid cityButton specified', cityButton);
        return false;
    }
    const cityModalContent =  document.getElementById('city-modal-content');
    if (cityModalContent === undefined || !cityModalContent) {
        console.log('cityModalInit: no orderCallModalContent found by id "city-modal-content"');
        return false;
    }
    cityButton.addEventListener('click', function(e) {
        e.preventDefault();
        popupS.modal({
            content: cityModalContent,
            additionalCloseBtnClass: 'city-modal-close',
            additionalBaseClass: 'city-modal-base',
            additionalPopupClass: 'city-modal-popup',
            closeBtn: '<img src="img/close-black-icon.png" alt="black crest">'
        });
    });
}

document.addEventListener('DOMContentLoaded', function(){
    const cityButtons = document.getElementsByClassName('city-modal-init');
    for(let i = 0; i < cityButtons.length; i++) {
        let cityButton = cityButtons.item(i);
        cityModalInit(cityButton);
    }
});
