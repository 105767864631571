let commons = require('../utils/commons');

function floatHeaderInit(headerNode, scrollY) {
    if (headerNode === undefined || !headerNode) {
        console.log('floatHeaderInit: no headerNode found')
        return false;
    }
    let screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    if (screenWidth <= 1280) {
        commons.removeClassName(headerNode, 'float');
        return false;
    }
    let top  = window.pageYOffset || document.documentElement.scrollTop;
    let left = window.pageXOffset || document.documentElement.scrollLeft;
    let screenOnTop = (top === 0);
    if (screenOnTop) {
        commons.removeClassName(headerNode, 'float');
    }
    if (top > 5) {
        if (!commons.hasClassName(headerNode, 'float')) {
            commons.addClassName(headerNode, 'float');
        }
    }
}

function toTopButtonInit(buttonNode, scrollY) {
    if (buttonNode === undefined || !buttonNode) {
        console.log('toTopButtonInit: no buttonNode found')
        return false;
    }
    let screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    if (screenWidth <= 1280) {
        commons.removeClassName(buttonNode, 'active');
        return false;
    }
    let top  = window.pageYOffset || document.documentElement.scrollTop;
    let left = window.pageXOffset || document.documentElement.scrollLeft;
    let screenOnTop = (top === 0);
    if (screenOnTop) {
        commons.removeClassName(buttonNode, 'active');
    }
    if (top > 5) {
        if (!commons.hasClassName(buttonNode, 'active')) {
            commons.addClassName(buttonNode, 'active');
        }
    }
}

const buttonContainerConst = document.getElementsByClassName('to-top-button')[0];
const headerContainerConst = document.getElementsByClassName('header-holder')[0];

document.addEventListener('DOMContentLoaded', function(){
    floatHeaderInit(headerContainerConst);
    toTopButtonInit(buttonContainerConst);
});

window.onscroll = function (e) {
    floatHeaderInit(headerContainerConst);
    toTopButtonInit(buttonContainerConst);
};
