module.exports.addClassName = function(element, className) {
    const classArray = (element.className) ? element.className.split(" ") : [];
    if (classArray.indexOf(className) == -1) {
        element.className += " " + className;
    }
};

module.exports.removeClassName = function(element, className) {
    const classArray = (element.className) ? element.className.split(" ") : [];
    const newclassArray = [];
    for(let i = 0; i < classArray.length; i++) {
        const currentClassName = classArray[i];
        if (currentClassName !== className) {
            newclassArray.push(currentClassName);
        }
    }
    element.className = newclassArray.join(" ");
};

module.exports.hasClassName = function(element, className) {
    const classArray = (element.className) ? element.className.split(" ") : [];
    for(let i = 0; i < classArray.length; i++) {
        const currentClassName = classArray[i];
        if (currentClassName === className) {
            return true;
        }
    }
    return false;
};
