var popupS = require('popups');
import popupSBasicStyles from "../../../node_modules/popups/css/popupS.min.css";

function consultationModalInit(consultationButton) {
    if (consultationButton === undefined || !consultationButton) {
        console.log('consultationModalInit: no valid consultationButton specified', consultationButton);
        return false;
    }
    const consultationModalContent =  document.getElementById('consultation-modal-content');
    if (consultationModalContent === undefined || !consultationModalContent) {
        console.log('consultationModalInit: no orderCallModalContent found by id "consultation-modal-content"');
        return false;
    }
    consultationButton.addEventListener('click', function(e) {
        e.preventDefault();
        popupS.modal({
            content: consultationModalContent,
            additionalCloseBtnClass: 'consultation-modal-close',
            additionalBaseClass: 'consultation-modal-base',
            additionalPopupClass: 'consultation-modal-popup',
            closeBtn: '<img src="img/close-black-icon.png" alt="black crest">'
        });
    });
}

document.addEventListener('DOMContentLoaded', function(){
    const consultationButtons = document.getElementsByClassName('consultation-modal-init');
    for(let i = 0; i < consultationButtons.length; i++) {
        let consultationButton = consultationButtons.item(i);
        consultationModalInit(consultationButton);
    }
});
